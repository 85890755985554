import { Space, Typography } from "antd"
import { Contact } from "../../../common/model/contacts"
import { ContactForm } from "../components/form/ContactForm"
import { resolveClientName } from "../components/render/ClientName"
import { detailsView } from "./DetailsView"

export const ContactDetailsView = detailsView<Contact>({
  itemsKey: "contacts",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <ContactForm itemId={item.id} onComplete={onComplete} onCancel={onCancel} />
  ),
  view: (item, appState) => {
    const projects = (appState.projects.data ?? []).filter(
      (p) => p.clientContactId === item.id,
    )

    return (
      <Space direction="vertical">
        <Typography.Text strong>First Name</Typography.Text>
        <Typography.Text>{item.firstName}</Typography.Text>
        <Typography.Text strong>Last Name</Typography.Text>
        <Typography.Text>{item.lastName}</Typography.Text>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Text>{item.email}</Typography.Text>
        <Typography.Text strong>Phone</Typography.Text>
        <Typography.Text>{item.phone}</Typography.Text>
        <Typography.Text strong>Client</Typography.Text>
        <Typography.Text>
          {resolveClientName(appState, item.clientId)}
        </Typography.Text>
        <Typography.Text strong>Projects ({projects.length})</Typography.Text>
        <Typography.Text>
          <ul>
            {projects.map((project) => (
              <li>
                {resolveClientName(appState, project.clientId)} / {project.name}
              </li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
