import { Typography } from "antd"
import { Certificate } from "../../../common/model/certificate"
import {
  CertificateForm,
  MergeCertificatesForm,
} from "../components/form/CertificateForm"
import { UploadForm } from "../components/form/UploadForm"
import { CertificateListItem } from "../model/certificates"
import { CertificateDetailsView } from "./CertificateDetailsView"
import { listingView } from "./ListingView"

export const CertificatesView = listingView<Certificate, CertificateListItem>({
  itemsKey: "certificates",
  addItemTitle: "New Certificate",
  confirmRemoveItemsQuestion:
    "Do you really want to remove the selected certificates?",
  addItemForm: <CertificateForm />,
  editItemForm: (item) => <CertificateForm itemId={item.id} />,
  detailsView: (item) => <CertificateDetailsView itemId={item.id} />,
  mergeItemsForm: (items) => (
    <MergeCertificatesForm itemIds={items.map((i) => i.id)} />
  ),
  uploadItemsForm: (
    <UploadForm
      itemsKey="certificates"
      examples={[
        {
          description: (
            <Typography.Text>
              Import certificates without the <code>id</code> column. Should be
              used when creating new certificates.
            </Typography.Text>
          ),
          rows: [
            "name, organization",
            "Developer Associate, Amazon Web Services",
          ],
        },
        {
          description: (
            <Typography.Text>
              Import certificates with the <code>id</code> column. Should be
              used when modifying existing certificates. Note that the{" "}
              <code>id</code> column can be empty.
            </Typography.Text>
          ),
          rows: [
            "name, organization, id",
            "Developer Associate, Amazon Web Services, 14a3e998-4ba5-4de4-94ad-ee5f8348f199",
            "SysOps Admin Associate, Amazon Web Services, 94eff251-de39-4bad-8f2c-05a5a189a988",
          ],
        },
      ]}
      rowData={[
        {
          rows: [
            {
              name: "name",
              required: true,
              description: (
                <Typography.Text>
                  Certificate name. Used to find an existing certificate to
                  update if the <code>id</code> isn't specified.
                </Typography.Text>
              ),
            },
            {
              name: "organization",
              required: true,
              description: (
                <Typography.Text>Issuing organization.</Typography.Text>
              ),
            },
            {
              name: "id",
              description: (
                <Typography.Text>
                  Certificate id. Used to find an existing certificate to
                  update.
                  <ul>
                    <li>
                      You must specify the id if you want to update name of an
                      existing certificate.
                    </li>
                    <li>
                      Import will fail if you specify an id that is not found
                      from the database.
                    </li>
                  </ul>
                </Typography.Text>
              ),
            },
          ],
        },
      ]}
    />
  ),
  actions: ["remove", "merge"],
  columns: [
    {
      property: "name",
      title: "Name",
    },
    {
      property: "organization",
      title: "Organization",
    },
    {
      property: "employeeCount",
      title: "Employees",
    },
  ],
  toListItem: (item, appState) => {
    const employeeCount = (appState.employees.data ?? []).filter((e) =>
      e.certifications?.some((c) => c.certificateId === item.id),
    ).length

    return {
      ...item,
      employeeCount,
      text: [item.name, item.organization].join(" ").toLowerCase(),
    }
  },
})
