import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"
import { v4 } from "uuid"

dayjs.extend(utc)

export const id = (): string => v4()

export const deepCopy = (obj: any): any => JSON.parse(JSON.stringify(obj))

export const currentLocalTime = (): Dayjs => dayjs()
export const formatLocalTimestamp = (timestamp: number): string =>
  dayjs(timestamp).format("DD.MM.YYYY HH:mm")

export const calculateDurationInDays = (
  now: Dayjs,
  startDate?: number,
  endDate?: number,
): number => {
  if (!startDate) {
    return 0
  }

  const start = dayjs(startDate).startOf("month")
  const end = dayjs(endDate ?? now).endOf("month")

  return end.diff(start, "days")
}

export const calculateDurationInMonths = (
  now: Dayjs,
  startDate?: number,
  endDate?: number,
): number => {
  if (!startDate) {
    return 0
  }

  const start = dayjs(startDate).startOf("month")
  const end = dayjs(endDate ?? now).endOf("month")

  return end.diff(start, "month") + 1
}
