import { Space, Typography } from "antd"
import { Employee } from "../../../common/model/employees"
import { EmployeeForm } from "../components/form/EmployeeForm"
import { resolveCertificateName } from "../components/render/CertificateName"
import { resolveClientName } from "../components/render/ClientName"
import { resolveVendorName } from "../components/render/VendorName"
import { detailsView } from "./DetailsView"

export const EmployeeDetailsView = detailsView<Employee>({
  itemsKey: "employees",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <EmployeeForm
      itemId={item.id}
      onComplete={onComplete}
      onCancel={onCancel}
    />
  ),
  view: (item, appState) => {
    const certifications = (item.certifications ?? [])
      .map((c) => resolveCertificateName(appState, c.certificateId, true))
      .sort()

    const certificationsElement =
      certifications.length > 0 ? (
        <Typography.Text>
          <ul>
            {certifications.map((certification) => (
              <li>{certification}</li>
            ))}
          </ul>
        </Typography.Text>
      ) : (
        <Typography.Text>-</Typography.Text>
      )

    const projects = (appState.projects.data ?? []).filter((p) =>
      p.assignments?.some((a) => a.employeeId === item.id),
    )

    const projectsElement =
      projects.length > 0 ? (
        <Typography.Text>
          <ul>
            {projects.map((project) => (
              <li>
                {resolveClientName(appState, project.clientId)} / {project.name}
              </li>
            ))}
          </ul>
        </Typography.Text>
      ) : (
        <Typography.Text>-</Typography.Text>
      )

    return (
      <Space direction="vertical">
        <Typography.Text strong>First Name</Typography.Text>
        <Typography.Text>{item.firstName}</Typography.Text>
        <Typography.Text strong>Last Name</Typography.Text>
        <Typography.Text>{item.lastName}</Typography.Text>
        <Typography.Text strong>Email</Typography.Text>
        <Typography.Text>{item.email}</Typography.Text>
        <Typography.Text strong>Current</Typography.Text>
        <Typography.Text>{item.current ? "Yes" : "No"}</Typography.Text>
        <Typography.Text strong>Vendor</Typography.Text>
        <Typography.Text>
          {resolveVendorName(appState, item.vendorId)}
        </Typography.Text>
        <Typography.Text strong>
          Certifications ({certifications.length})
        </Typography.Text>
        {certificationsElement}
        <Typography.Text strong>Projects ({projects.length})</Typography.Text>
        {projectsElement}
      </Space>
    )
  },
})
