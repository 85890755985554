import { Space, Typography } from "antd"
import { Certificate } from "../../../common/model/certificate"
import { CertificateForm } from "../components/form/CertificateForm"
import { detailsView } from "./DetailsView"

export const CertificateDetailsView = detailsView<Certificate>({
  itemsKey: "certificates",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <CertificateForm
      itemId={item.id}
      onComplete={onComplete}
      onCancel={onCancel}
    />
  ),
  view: (item, appState) => {
    const employees = (appState.employees.data ?? [])
      .filter((e) => e.certifications?.some((c) => c.certificateId === item.id))
      .sort((a, b) =>
        (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName),
      )

    return (
      <Space direction="vertical">
        <Typography.Text strong>Organization</Typography.Text>
        <Typography.Text>{item.organization}</Typography.Text>
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Employees ({employees.length})</Typography.Text>
        <Typography.Text>
          <ul>
            {employees.map((employee) => (
              <li>
                {employee.lastName} {employee.firstName}
              </li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
