import { Space, Typography } from "antd"
import { Vertical } from "../../../common/model/vertical"
import { VerticalForm } from "../components/form/VerticalForm"
import { detailsView } from "./DetailsView"

export const VerticalDetailsView = detailsView<Vertical>({
  itemsKey: "verticals",
  editItemForm: ({ item, onCancel, onComplete }) => (
    <VerticalForm
      itemId={item.id}
      onComplete={onComplete}
      onCancel={onCancel}
    />
  ),
  view: (item, appState) => {
    const clients = (appState.clients.data ?? [])
      .filter((c) => c.verticalId === item.id)
      .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <Space direction="vertical">
        <Typography.Text strong>Name</Typography.Text>
        <Typography.Text>{item.name}</Typography.Text>
        <Typography.Text strong>Clients ({clients.length})</Typography.Text>
        <Typography.Text>
          <ul>
            {clients.map((client) => (
              <li>{client.name}</li>
            ))}
          </ul>
        </Typography.Text>
      </Space>
    )
  },
})
